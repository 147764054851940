/**
 * Main JS file for theme behaviours
 */

(function ($) {
    "use strict";

    $(document).ready(function() {

        // Mobile Nav Menu JS --------------------------------
        var menu_id = "#js-navigation-centered-menu";
        var menuToggle = $("#js-navigation-centered-mobile-menu").unbind();
        $(menu_id).removeClass("show");

        // Close the menu if a link was clicked
        $('.nav-link').click(function(){
            if($(menu_id).is(":visible")) {
                $(menu_id).removeAttr("style");
            }
        });

        // Toggle the menu when menu-icon is clicked
        menuToggle.on("click", function(e) {
            e.preventDefault();
            $(menu_id).slideToggle(function(){
                if($(menu_id).is(":hidden")) {
                    $(menu_id).removeAttr("style");
                }
            });
        });

        jQuery.mark = {
            jump: function (options) {
                var defaults = {
                    selector: 'a.scroll-on-page-link'
                };
                if (typeof options == 'string') {
                    defaults.selector = options;
                }

                options = jQuery.extend(defaults, options);
                return jQuery(options.selector).click(function (e) {
                    var jumpobj = jQuery(this);
                    var target = jumpobj.attr('href');
                    var thespeed = 750;
                    var offset = jQuery(target).offset().top;
                    jQuery('html,body').animate({
                        scrollTop: offset
                    }, thespeed, 'swing');
                    e.preventDefault();
                });
            }
        };
        jQuery.mark.jump();

        // Modal -------------------------------------------
        $(".trailer-btn").click(function(){
            $("#trailer-modal").prop("checked", true);
        });
        // $(".contact-btn").click(function(){
        //     $("#contact-modal").prop("checked", true);
        //     return false;
        // });

        // Adds body class when modal is open
        $(".modal-state").on("change", function() {
            if ($(this).is(":checked")) {
                $("body").addClass("modal-open");
            } else {
                $("body").removeClass("modal-open");
            }
        });

        // Close on click
        $(".modal-fade-screen, .modal-close").on("click", function() {
            $(".modal-state:checked").prop("checked", false).change();
        });

        // Stop click propagation
        $(".modal-inner").on("click", function(e) {
            e.stopPropagation();
        });

        // Contact Form ------------------------------------
        $('form[id=contact_form]').submit(function(e){
            e.preventDefault();
            $.ajax({
                url: $(this).attr('action'),
                method: 'POST',
                data: $(this).serialize(),
                dataType: 'json',
                beforeSend: function() {
                    $('form[id=contact_form] .success').hide();
                    $('form[id=contact_form] .error').hide();
                    $('form[id=contact_form] .sending').show();
                },
                success: function(data) {
                    console.log(data);
                    $('form[id=contact_form] .sending').hide();
                    $('form[id=contact_form] .success').show();
                },
                error: function(err) {
                    $('form[id=contact_form] .sending').hide();
                    $('form[id=contact_form] .error').show();
                }
            });
            return false;
        });

        // Quote Rotator -----------------------------------
        var quote_rotator = {
            cssid: null,
            rotate: function() {
                var self = this;
                var current = $(self.cssid + ' > .current');
                if(current.next().length) {
                    current.removeClass('current').fadeOut(1000);
                    current.next().addClass('current').fadeIn(1000);
                } else {
                    current.removeClass('current').fadeOut(1000);
                    $(self.cssid).children().first().addClass('current').fadeIn(1000);
                }
            },
            init: function(id) {
                var self = this;
                self.cssid = id;
                if($(id).children().length > 1) {
                    $(id).children().hide();
                    $(id).children().first().addClass('current').fadeIn(1000);
                    setInterval(function(){
                        self.rotate();
                    }, 6000);
                }
            }
        }
        quote_rotator.init('#reviews');

        document.getElementById("CopyrightYear").innerHTML = new Date().getFullYear();
    });
}(jQuery));
